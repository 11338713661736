<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section">
    <base-v-component heading="Subscriber List"/>
    <base-material-card
      icon="mdi-account-multiple-check"
      title="Subscribers List"
      class="px-5 py-3">
      <div class="text-right">
        <div class="d-inline-flex">
          <v-btn class="info align-end" :loading="downloading" @click="requestDownloadFile" :disabled="downloading || downloadable_file_status === 'in_queue'">
            <v-icon left>
              mdi-folder-plus-outline
            </v-icon>
            <template v-if="fresh_download_file">
              request a download file
            </template>
            <template v-else>
              regenerate new download file
            </template>
          </v-btn>
          <v-btn class="primary align-end" :href="downloadable_file" download v-if="downloadable_file_status === 'completed'">
            <span class="mb-3">
               <v-icon left>
                mdi-download
               </v-icon>
              Download
            </span>
          </v-btn>
        </div>
        <div class="align-end subtitle-1 grey--text" v-if="downloadable_file_status === 'in_queue' && fresh_download_file">
          * download file requested, and will be available soon
        </div>
        <div class="align-end subtitle-1 grey--text" v-if="downloadable_file_status === 'in_queue' && !fresh_download_file">
          * the new generated file will be available soon
        </div>
      </div>
      <v-simple-table>
        <filter-list
          ref="filterComponent"
          :filters="filter"
          :on-change="getSubscribers">
        </filter-list>
        <tbody>
        <tr v-for="subscriber in subscribers">
          <td>{{ subscriber.id }}</td>
          <td>{{ subscriber.first_name }}</td>
          <td>{{ subscriber.last_name }}</td>
          <td>{{ subscriber.branch_name?subscriber.branch_name:'--' }}</td>
          <td>{{ subscriber.country_name?subscriber.country_name:'--' }}</td>
          <td>{{ subscriber.email }}</td>
          <td>
            <v-chip class="ma-2" :class="{'red white--text': subscriber.is_duplicate}">
              {{ subscriber.mobile }}
            </v-chip>
          </td>
          <td>{{ subscriber.travel_date }}</td>
          <td>{{ subscriber.sheet ? subscriber.sheet.name: '---' }}</td>
          <td>{{ subscriber.subscription_status }}</td>
          <td>{{ subscriber.is_duplicate | isDuplicated }}</td>
          <td>{{ subscriber.is_mandatory | isMandatory }}</td>
          <td>{{ subscriber.created_at }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="text-center">
      <v-pagination
        v-if="pagination.total > 10"
        v-model="query.page"
        :length="pagination.length"
        :total-visible="7"
        @input="getSubscribers"
        @next="getSubscribers"
        @previous="getSubscribers"
      />
    </div>
    <div class="py-3"/>
    <v-btn x-large fab dark fixed right bottom color="blue" to="/subscribers/create">
      <v-icon large>mdi-plus</v-icon>
    </v-btn>

  </v-container>
</template>

<script>
  import DeleteDialog from '../../components/base/DeleteDialog'
  import FilterList from "../../components/base/FilterList";

  export default {
    name: 'SubscribersList',
    components: {FilterList, DeleteDialog},
    data() {
      return {
        downloading: false,
        downloadable_file_status: null,
        downloadable_file: null,
        fresh_download_file: true,
        subscribers: [],
        pagination: {
          page: 1,
          length: 0,
          total: 0
        },
        query: {
          page: 1
        },
        filter: [
          {
            name: "page",
            value: 1,
            type: "pagination"
          },
          {
            name: "id",
            value: "",
            type: "text_field"
          },
          {
            name: "first_name",
            value: "",
            type: "text_field"
          },
          {
            name: "last_name",
            value: "",
            type: "text_field"
          },
          {
            name: "branch",
            value: "",
            type: "text_field"
          },
          {
            name: "email",
            value: "",
            type: "text_field"
          },
          {
            name: "mobile",
            value: "",
            type: "text_field"
          },
          {
            name: "travel_date",
            value: "",
            type: "text_field"
          },
          {
            name: "sheet_name",
            value: "",
            type: "text_field"
          },
          {
            name: "subscription_status",
            value: "",
            type: "select",
            items: [
              {text: "Pending", value: 'pending'},
              {text: "Active", value: 'active'},
              {text: "Expired", value: 'expired'},
            ]
          },
          {
            name: "is_duplicate",
            value: "",
            type: "select",
            items: [
              {text: "Yes", value: 1},
              {text: "No", value: 0},
            ]
          },
          {
            name: "is_mandatory",
            value: "",
            type: "select",
            items: [
              {text: "Yes", value: 1},
              {text: "No", value: 0},
            ]
          },
          {
            name: "created_at",
            value: "",
            type: "text_field"
          },
        ]
      }
    },
    created() {
      this.getUrlParams();
      this.getSubscribers();
    },
    watch: {
      $route() {
        this.getUrlParams();
        this.getSubscribers();
      }
    },
    methods: {
      getSubscribers() {
        this.$authApi.get(`/subscribers`, {
          params: this.query
        }).then(res => {
          this.downloadable_file_status = res.data.data.downloadable_file_status;
          this.downloadable_file = res.data.data.downloadable_file;
          this.fresh_download_file = res.data.data.fresh_file;
          this.subscribers = res.data.data.subscribers.data;
          this.pagination.length = res.data.data.subscribers.last_page;
          this.pagination.total = res.data.data.subscribers.total;
        })
      },
      requestDownloadFile() {
        this.$authApi.post('subscribers/export').then(res => {
          this.fresh_download_file = res.data.data.fresh_file;
          this.downloadable_file_status = 'in_queue';
        })
      },
      downloadList() {
        this.downloading = true;
        this.$downloadApi.get('subscribers/export').then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Partner Subscribers.xlsx');
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        })
      },
      getUrlParams() {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
            page: 1
          };
          this.filter = [
            {
              name: "page",
              value: 1,
              type: "pagination"
            },
            {
              name: "id",
              value: "",
              type: "text_field"
            },
            {
              name: "first_name",
              value: "",
              type: "text_field"
            },
            {
              name: "last_name",
              value: "",
              type: "text_field"
            },
            {
              name: "branch",
              value: "",
              type: "text_field"
            },
            {
              name: "country",
              value: "",
              type: "text_field"
            },
            {
              name: "email",
              value: "",
              type: "text_field"
            },
            {
              name: "mobile",
              value: "",
              type: "text_field"
            },
            {
              name: "travel_date",
              value: "",
              type: "text_field"
            },
            {
              name: "sheet_name",
              value: "",
              type: "text_field"
            },
            {
              name: "subscription_status",
              value: "",
              type: "select",
              items: [
                {text: "Pending", value: 'pending'},
                {text: "Active", value: 'active'},
                {text: "Expired", value: 'expired'},
              ]
            },
            {
              name: "is_duplicate",
              value: "",
              type: "select",
              items: [
                {text: "Yes", value: 1},
                {text: "No", value: 0},
              ]
            },
            {
              name: "is_mandatory",
              value: "",
              type: "select",
              items: [
                {text: "Yes", value: 1},
                {text: "No", value: 0},
              ]
            },
            {
              name: "created_at",
              value: "",
              type: "text_field"
            },
          ];
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query;
          this.filter.forEach(param => {
            param.value = this.query[param['name']];
          });
        }
      }
    },
  }
</script>
